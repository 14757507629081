import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="company-custom-field"
export default class extends Controller {
  static targets = [ "label", "input" ]

  connect() {
    this.dispatch('requestLocation');
  }

  handleLocationOptionChange({ detail: { element } }) {
    const customFieldName = element.dataset.customFieldName;

    if (customFieldName) {
      this.labelTarget.textContent = customFieldName;
      this.inputTarget.required = true;
      this.inputTarget.addEventListener("blur", formInputUpdate);
      this.inputTarget.addEventListener("keyup", formInputUpdate);
      this.element.classList.remove("hide");
    } else {
      this.inputTarget.required = false;
      this.inputTarget.removeEventListener("blur", formInputUpdate);
      this.inputTarget.removeEventListener("keyup", formInputUpdate);
      this.element.classList.add("hide");
    }
  }
}
